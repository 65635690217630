<!--
 * @Descripttion:
 * @Author: 秦永莲
 * @Date: 2021-03-19
 * @LastEditTime: 2021-03-19
 -->
<template>
  <div class="consult-container">
    <div class="consult-top">
      <Header class="page_hea" />
      <div class="img_header">
        <div class="header_text">来这里，了解更多精彩内容</div>
      </div>
      
    </div>
    <!-- 中间内容区域   -->
    <div class="consult-content"> 
       <el-table :data="showConsultData" 
        stripe
        :header-row-style="headerRowStyle"
        :row-style="rowStyle"
        :cell-class-name="cellClassName"
        :header-cell-class-name="headerClassName"
        style="border-radius: 4px;"
        height="500px"
       >
          <el-table-column
            type="index"
            label="序号"
            width="100"
            align="center"
          />
          <el-table-column
            prop="SCXW_FL"
            label="分类"
            width="180"
            align="center"
          />
          <el-table-column
            prop="SCXW_BT"
            label="标题"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <a :href="`/jepaas/mallInformation/load.htm?id=${scope.row.CRM_YYGL_SCXW_ID}`"  target="_blank">{{scope.row.SCXW_BT}}</a>
            </template>
          </el-table-column>
          <el-table-column
            prop="SCXW_SJ"
            label="时间"
            width="200"
            align="center"
          />
        </el-table>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import Header from '@/components/Header'
import { POST_CONSULT_URL } from '@/pages/index/actions/api';


export default {
  components: {
    Header,
  },
  props: {},
  data() {
    return {
      showConsultData: [], // 咨询的数据
    };
  },
  computed: {},
  watch: {},
  created() {
    // 初始化获取资询的数据
    this.getConsultData();
  },
  mounted() {
  },
  updated() {
  },
  methods: {
    headerClassName(){
      return 'cell-class-line';
    },
    cellClassName(){
      return 'cell-class';
    },
    headerRowStyle(){
        return { fontSize: '18px',color: '#3F3F3F',height: '50px' };
    },
    rowStyle() {
        return { fontSize: '18px',color: '#3F3F3F',height: '50px',cursor: 'pointer' };
    },
    getConsultData(){
      const that = this;
      that.showConsultData = [];
       this.$ajax({
        url: POST_CONSULT_URL,
        data: {
        },
      }).then((obj) => {
        if(obj.data.success){
          const data = obj.data.obj || [];
          data.forEach(item => {
            const { CRM_YYGL_SCXW_ID , SCXW_FL, SCXW_SJ, SCXW_BT } = item.values;
            that.showConsultData.push({CRM_YYGL_SCXW_ID , SCXW_FL, SCXW_SJ, SCXW_BT});
          })
        }
      }).catch((err)=> {
        console.log(err);
      })
    },
  },
};
</script>
<style>
.consult-container .el-table__empty-text{
  font-size: 18px!important;
  color: #3F3F3F!important;
}
.consult-container .cell-class, .consult-container .is-leaf{
  border-bottom: 0px solid transparent!important;
}
.consult-container tbody .cell-class:hover:nth-of-type(3){
  color: #3468CD!important;
}
.consult-container thead .cell-class-line{
  border-bottom: 1px solid #d8d8d8!important;
}
.consult-container .el-table::before{
  height: 0!important;
}
.consult-container .el-table__row--striped{
  background: #F5F5F5!important;
}

</style>
<style rel="stylesheet/less" lang="less" scoped>
.consult-container {
  position:absolute;
  top:0;
  left:0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  a{
    text-decoration:none;
    color: #3468CD!important;
  }
  .consult-top{
    width: 100%;
    height: 200px;
    background-image: url('./../../../../assets/imgs/consult/header.png');
    background-size: cover;
    background-repeat:no-repeat;
    .img_header{
      height: 120px;
      width: 100%;
      position: relative;
      top: 80px;
      img{
        height: 100%;
        width: 100%;
      }
      .header_text{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 600;
        font-size: 48px;
        color: #FFFFFF;
        letter-spacing: 0;
        text-align: center;
      }
    }
  }
  
  .consult-content{
    position: absolute;
    top: 240px;
    bottom: 0;
    width: 1250px;
    left: 50%;
    transform: translateX(-50%);
    overflow: scroll;
  }
}
</style>
